<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App'
};
</script>

<style>
html {
  overflow-y: auto;
}
</style>
