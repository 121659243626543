import api from '@/plugins/api';
import { isEmpty } from 'lodash';

export const keyToken = 'nebula-token';

export const login = (credentials) => api.login(credentials)
  .then(async (res) => {
    const accessToken = res.headers.authorization;
    window.localStorage.setItem(keyToken, accessToken);
    window.localStorage.setItem('user', JSON.stringify(res.data));
  });

export const logout = () => {
  window.localStorage.removeItem(keyToken);
  window.localStorage.removeItem('user');
};

export const isAuthenticated = () => !isEmpty(localStorage.getItem(keyToken));

export const resetLocalStorage = async () => {
  if (typeof window.localStorage[keyToken]) {
    window.localStorage.removeItem(keyToken);
  }
};
