import App from '@/App.vue';
import '@/plugins/vuelidate';
import vuetify from '@/plugins/vuetify';
import Routers from '@/routers';
import store from '@/store';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import VueMask from 'v-mask';
import Vue from 'vue';

Vue.use(VueMask);
Vue.config.productionTip = false;
window.Vue = new Vue({
  router: Routers,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
