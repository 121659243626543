const LegalEntities = () => import('@/modules/legal-entities/views/LegalEntities.vue');
const LegalEntitiesReports = () => import('@/modules/legal-entities/views/LegalEntitiesReports.vue');
const ExportLegalEntities = () => import('@/modules/legal-entities/views/ExportLegalEntities.vue');

export default [
  {
    path: 'legal-entities',
    name: 'LegalEntities',
    component: LegalEntities,
    meta: { requiresAuth: true }
  },
  {
    path: 'legal-entities-reports',
    name: 'LegalEntitiesReports',
    component: LegalEntitiesReports,
    meta: { requiresAuth: true }
  },
  {
    path: 'export-legal-entities',
    name: 'ExportLegalEntities',
    component: ExportLegalEntities,
    meta: { requiresAuth: true }
  }
];
