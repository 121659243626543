import { login, logout } from '@/modules/auth/services/auth-service';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  title: undefined,
  user: {}
};

const types = {
  SET_TITLE: 'SET_TITLE',
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER'
};

const mutations = {
  [types.SET_TITLE]: (state, { title }) => {
    state.title = title;
  },
  [types.SET_USER]: (state, { user }) => {
    state.user = user;
  },
  [types.REMOVE_USER]: (state) => {
    state.user = {};
  }
};

const actions = {
  setTitle: ({ commit }, payload) => {
    commit(types.SET_TITLE, payload);
  },

  async signIn({ commit }, credentials) {
    commit(types.SET_USER, credentials);

    return login(credentials);
  },

  async signOut({ commit }) {
    commit(types.REMOVE_USER);

    return logout();
  },

  getUser: () => state.user
};

export default new Vuex.Store({
  state,
  mutations,
  actions
});
