import authRoutes from '@/modules/auth/router';
import dashboardRoutes from '@/modules/dashboard/router/index';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...authRoutes,
    ...dashboardRoutes,
    { path: '/:pathMatch(.*)*', redirect: '/login' }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const token = window.localStorage.getItem('nebula-token');
    const loginRoute = { path: '/login', query: { redirect: to.fullPath } };

    if (token) return next();

    return next(loginRoute);
  }

  next();
});

export default router;
