import Buzz from '../../buzz/router';
import LegalEntitiesRouter from '../../legal-entities/router';
import PrivatePersonRouter from '../../private-person/router';
import MistakesRouter from '../../mistakes/router';

const Dashboard = () => import('@/modules/dashboard/views/Dashboard.vue');

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      ...Buzz,
      ...LegalEntitiesRouter,
      ...PrivatePersonRouter,
      ...MistakesRouter
    ],
  },
];
