const Buzz = () => import('@/modules/buzz/views/Buzz.vue');
const BuzzReports = () => import('@/modules/buzz/views/BuzzReports.vue');

export default [
  {
    path: 'buzz',
    name: 'Buzz',
    component: Buzz,
    meta: { requiresAuth: true }
  },
  {
    path: 'buzz-reports',
    name: 'BuzzReports',
    component: BuzzReports,
    meta: { requiresAuth: true }
  }
];
