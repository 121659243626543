const PrivatePerson = () => import('@/modules/private-person/views/PrivatePerson.vue');
const PrivatePersonReports = () => import('@/modules/private-person/views/PrivatePersonReports.vue');
const ExportPrivatePerson = () => import('@/modules/private-person/views/ExportPrivatePerson.vue');

export default [
  {
    path: 'private-person',
    name: 'PrivatePerson',
    component: PrivatePerson,
    meta: { requiresAuth: true }
  },
  {
    path: 'private-person-reports',
    name: 'PrivatePersonReports',
    component: PrivatePersonReports,
    meta: { requiresAuth: true }
  },
  {
    path: 'export-private-person',
    name: 'ExportPrivatePerson',
    component: ExportPrivatePerson,
    meta: { requiresAuth: true }
  }
];
