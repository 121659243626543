const MistakesReport = () => import('@/modules/mistakes/views/MistakesReport.vue');

export default [
  {
    path: 'mistakes',
    name: 'MistakesReport',
    component: MistakesReport,
    meta: { requiresAuth: true }
  }
];
