// import axiosConfig from '@/config/axios';
import axios from 'axios';
import _ from 'lodash';

const apiRequest = axios.create({
  baseURL: process.env.VUE_APP_API
});

const apiService = {

  // Legal Entities
  getProjects() {
    return apiRequest.get('/projects');
  },

  getCSVPending(formData) {
    return apiRequest.post('/legal_entities/get_csv_pending', formData);
  },

  inputCsvEnriched(formData) {
    return apiRequest.post('/legal_entities/input_csv_enriched', formData);
  },

  exportSheetId(projectSelectedId) {
    return apiRequest.get(`legal_entities/export_sheet_id/${projectSelectedId}`);
  },

  getInfoNameLegalEntity(name) {
    return apiRequest.get('/legal_entities/get_info/name', { params: { name } });
  },

  updateLegalEntities(editedItem) {
    return apiRequest.put(`/legal_entities/${editedItem.id}`, editedItem);
  },

  // Private People
  getPrivatePeople() {
    return apiRequest.get('/private_people');
  },

  getInfoNamePrivatePeople(name) {
    return apiRequest.get('/private_people/get_info/name', { params: { name } });
  },

  privatePeopleExportSheetId(projectSelectedId) {
    return apiRequest.get(`private_people/export_sheet_id/${projectSelectedId}`);
  },
  privatePeopleGetCsvPending(formData) {
    return apiRequest.post('/private_people/get_csv_pending', formData);
  },

  privatePeopleInputCsvEnriched(formData) {
    return apiRequest.post('/private_people/input_csv_enriched', formData);
  },

  createPrivatePeople(private_people) {
    return apiRequest.post('/private_people', private_people);
  },

  updatePrivatePeople(private_people) {
    return apiRequest.put(`/private_people/${private_people.id}`, private_people);
  },

  // Legal Entity
  getLegalEntity() {
    return apiRequest.get('/legal_entities');
  },

  createLegalEntity(legal_entity) {
    return apiRequest.post('/legal_entities', legal_entity);
  },

  updateLegalEntity(legal_entity) {
    return apiRequest.put(`/legal_entities/${legal_entity.id}`, legal_entity);
  },

  // BUZZ
  getBuzzs() {
    return apiRequest.get('/buzzs');
  },

  createBuzz(buzz) {
    return apiRequest.post('/buzzs', buzz);
  },

  updateBuzz(buzz) {
    return apiRequest.put(`/buzzs${buzz.id}`, buzz);
  },

  buzzGetCsvPending(formData) {
    return apiRequest.post('/buzzs/get_csv_pending', formData);
  },

  buzzInputCsvEnriched(formData) {
    return apiRequest.post('/buzzs/input_csv_enriched', formData);
  },

  // Login Logout

  login: (credentials) => {
    return apiRequest.post('/login', credentials);
  },
};

apiRequest.interceptors.request.use((config) => {
  return { ...config, headers: { ...config.headers, Authorization: localStorage.getItem('nebula-token') } };
});

apiRequest.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('nebula-token');
    const vm = window.Vue;
    if (!_.includes(['/login'], vm.$router.name)) {
      vm.$store.dispatch('signOut').then(() => {
        vm.$router.push('/login');
      });
    }
  } else {
    throw error;
  }
});

export default apiService;
